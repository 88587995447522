import * as React from 'react';
import { useTranslation } from 'react-i18next';
export var DisclaimerText = function () {
    var t = useTranslation().t;
    return (React.createElement("div", { className: "pb-4 text-xs font-medium text-dark-gray" },
        t('defaultDisclaimerText.one', 'If you think you are in need of emergency medical assistance, do'),
        ' ',
        t('defaultDisclaimerText.two', 'not'),
        ' ',
        t('defaultDisclaimerText.three', 'rely on Clearstep. You should'),
        React.createElement("b", null,
            " ",
            t('defaultDisclaimerText.four', 'immediately'),
            " "),
        ' ',
        t('defaultDisclaimerText.five', 'call 911 or reach out to your local emergency medical service. If you are unsure whether you are in need of emergency medical assistance, call your doctor or visit the closest emergency room/department as soon as possible. Clearstep cannot guarantee the accuracy of any information conveyed by any of its services. You should never understand any information resulting from engagement with Clearstep and its services to be perfectly accurate and entirely reliable. Any information provided by Clearstep is only educational and'),
        React.createElement("b", null,
            " ",
            t('defaultDisclaimerText.six', 'cannot'),
            " "),
        t('defaultDisclaimerText.seven', 'supplant the advice or guidance of a physician. Clearstep does not diagnose and only provides information to support your decision making. For actionable medical diagnoses, please consult your physician or other doctor. Clearstep expressly disavows any liability or responsibility resulting from your engagement with any content. You are solely responsible for your engagement with or reliance upon any content, regardless of whether or not you choose to engage with content responsibly and truthfully.')));
};
