var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { getAppPath } from '../ProxyPath';
import { postGenerateI18n } from '../../api/I18n';
import { getPartnerName } from '../../components/clearstep/util/Theme';
import { SETTINGS_NAMESPACE, STATIC_NAMESPACE, enUS, esMX } from './constants';
import { getAllEnabledNamespaces } from './split';
import { setPageTitle } from './pageTitle';
var i18n = i18next
    // Load translations from http endpoints (see config)
    .use(Backend)
    // Auto detect language (may need alternate approach, as we have internal
    // language settings? Also how do we set translations for existing stuff?)
    .use(LanguageDetector)
    // Pass i18n down to react-i18next
    .use(initReactI18next);
// Handler for using /generate endpoint to add missing keys in i18n service
var addMissingKey = function (language, namespace, key, defaultVal) {
    var _a;
    return postGenerateI18n({
        language: language,
        namespace: namespace,
        body: (_a = {}, _a[key] = defaultVal, _a),
    }).then(function (update) {
        if (!update) {
            // POST call errored out - logged by postGenerateI18n
            return;
        }
        if (!Object.prototype.hasOwnProperty.call(update, key)) {
            console.error("missingKeyHandler Error: no key \"".concat(key, "\" in api payload \"").concat(JSON.stringify(update), "\""));
        }
        else if (update[key]) {
            // Current mock behavior will return empty records, so only add if
            // update is present (avoiding infinite loop)
            i18n.addResource(language, namespace, key, update[key]);
        }
    });
};
// Handler for interpolating URL – routing static namespace to static export
// JSON file, with other namespaces loaded from i18n service.
var getLoadPath = function (locale, namespace) {
    if (namespace === STATIC_NAMESPACE) {
        return getAppPath("/static/locales/".concat(locale, "/").concat(namespace, ".json"));
    }
    return getAppPath("/translations/".concat(getPartnerName(), "/").concat(locale, "/").concat(namespace));
};
var initOptions = {
    saveMissing: true,
    saveMissingTo: 'current',
    missingKeyHandler: function (lngs, ns, key, defaultVal) {
        lngs.forEach(function (lng) { return addMissingKey(lng, ns, key, defaultVal); });
    },
    // Uncomment to add verbose logging
    // debug: true,
    // Configure languages
    supportedLngs: [enUS, esMX],
    load: 'currentOnly',
    // Default text is used conistently across application, and setting to en-US
    // causes issues with missingKeyHandler
    fallbackLng: false,
    // Additional translation namespaces only loaded as needed, based on spilt config
    ns: [STATIC_NAMESPACE],
    // Prevents empty layouts when translations are not present
    returnEmptyString: false,
    interpolation: {
        escapeValue: false, // react already safe from xss
    },
    detection: {
        // Default
        // caches: ['localStorage'],
        // order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
        caches: [],
        order: ['querystring', 'sessionStorage', 'navigator', 'htmlTag'],
        // Match query string detection on the backend (?language=es-MX)
        lookupQuerystring: 'language',
        // Set in template
        lookupSessionStorage: 'i18nextLanguage',
    },
    react: {
        // Adds overhead to app to wrap all compoents using the translation hook
        // in a <Suspense> tag. Instead, we're using a callback to manually block
        // rendering (see ConversationWrapper.tsx)
        useSuspense: false,
        bindI18nStore: 'added',
    },
    backend: {
        // Note: can expect only one language/namespace. From the docs: "If not
        // used with i18next-multiload-backend-adapter, lngs and namespaces will
        // have only one element each"
        loadPath: function (_a, _b) {
            var locale = _a[0];
            var namespace = _b[0];
            return getLoadPath(locale, namespace);
        },
        // for all available options read the backend's repository readme file: https://github.com/i18next/i18next-http-backend#backend-options
    },
};
// Curried to expose callback to React rendering hook
export var i18nInit = function (callback) { return __awaiter(void 0, void 0, void 0, function () {
    var splitNs, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 5]);
                return [4 /*yield*/, i18n.init(initOptions)];
            case 1:
                _a.sent();
                return [4 /*yield*/, getAllEnabledNamespaces()];
            case 2:
                splitNs = _a.sent();
                return [4 /*yield*/, i18n.loadNamespaces(splitNs, callback)];
            case 3:
                _a.sent();
                // Set page title after load if NS is enabled
                if (splitNs.includes(SETTINGS_NAMESPACE)) {
                    setPageTitle(i18n.t);
                }
                return [3 /*break*/, 5];
            case 4:
                err_1 = _a.sent();
                // Callback anyways, since i18next init failure should not block rendering
                if (callback) {
                    callback(err_1, i18n.t);
                }
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}); };
export default i18n;
