var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
/* eslint-disable react/display-name */
// see: https://preactjs.com/guide/v10/debugging#strip-devtools-from-production
// Must be the first import
import { isEmpty } from 'lodash-es';
import 'preact/devtools';
// First things first, wire up error reporting.
import './util/InstallErrorReporter';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import 'react-tooltip/dist/react-tooltip.css';
import './components/clearstep/Education/Education';
import './components/clearstep/Conversation/Conversation';
import { THEME } from './components/clearstep/util/Theme';
import './components/tailwind_clearstep/ResultPage/ResultPage';
import './components/clearstep/ErrorPage/ErrorPage';
import './components/clearstep/HistoricalConversation/HistoricalConversation';
import './components/clearstep/DownloadSymptomDiagnostic/DownloadSymptomDiagnostic';
var BookAppointment = React.lazy(function () { return import('./components/clearstep/BookAppointment/BookAppointment'); });
var ConversationWrapper = React.lazy(function () { return import('./components/clearstep/Conversation/ConversationWrapper'); });
var Education = React.lazy(function () { return import('./components/clearstep/Education/Education'); });
var AerialLogin = React.lazy(function () { return import('./components/tailwind_clearstep/Partners/aerial/AerialLogin'); });
var BaycareEmployeeLogin = React.lazy(function () {
    return import('./components/tailwind_clearstep/Partners/corona/baycare-partner/BaycareEmployeeLogin');
});
var MountSinaiFindCare = React.lazy(function () { return import('./components/tailwind_clearstep/MountSinaiFindCare/MountSinaiFindCare'); });
var ErrorPage = React.lazy(function () { return import('./components/clearstep/ErrorPage/ErrorPage'); });
var LTCS = React.lazy(function () { return import('./components/clearstep/LTCS/LTCS'); });
var EducationMarkdownEditorRoute = React.lazy(function () {
    return import('./components/tailwind_clearstep/EducationMarkdownEditorRoute/EducationMarkdownEditorRoute');
});
var HistoricalConversation = React.lazy(function () { return import('./components/clearstep/HistoricalConversation/HistoricalConversation'); });
var DownloadSymptomDiagnostic = React.lazy(function () { return import('./components/clearstep/DownloadSymptomDiagnostic/DownloadSymptomDiagnostic'); });
var ResultPage = React.lazy(function () { return import('./components/tailwind_clearstep/ResultPage/ResultPage'); });
var WidgetWrapper = React.lazy(function () { return import('./components/Widget/WidgetWrapper'); });
function GenericSuspense(Component) {
    return function (props) { return (React.createElement(React.Suspense, { fallback: "" },
        React.createElement(Component, __assign({}, props)))); };
}
if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line global-require
    require('preact/debug');
}
var partnerGtm = (_a = THEME === null || THEME === void 0 ? void 0 : THEME.analyticsCustomizations) === null || _a === void 0 ? void 0 : _a.partnerGtm;
if (!isEmpty(partnerGtm)) {
    var tagManagerArgs = {
        gtmId: partnerGtm,
    };
    TagManager.initialize(tagManagerArgs);
}
window.ReactDOM = ReactDOM;
window.React = React;
window.Conversation = GenericSuspense(ConversationWrapper);
window.Education = GenericSuspense(Education);
window.ResultPage = GenericSuspense(ResultPage);
window.EducationMarkdownEditorRoute = GenericSuspense(EducationMarkdownEditorRoute);
window.BaycareEmployeeLogin = GenericSuspense(BaycareEmployeeLogin);
window.AerialLogin = GenericSuspense(AerialLogin);
window.MountSinaiFindCare = GenericSuspense(MountSinaiFindCare);
window.BookAppointment = GenericSuspense(BookAppointment);
window.ErrorPage = GenericSuspense(ErrorPage);
window.LTCS = GenericSuspense(LTCS);
window.HistoricalConversation =
    GenericSuspense(HistoricalConversation);
window.DownloadSymptomDiagnostic =
    GenericSuspense(DownloadSymptomDiagnostic);
window.WidgetWrapper = GenericSuspense(WidgetWrapper);
