/* GENERATED FROM proto/results.proto. DO NOT EDIT MANUALLY. */
/* tslint:disabled */
/* eslint-disable */
export var SourceOfCare;
(function (SourceOfCare) {
    var Type;
    (function (Type) {
        Type["UNKNOWN"] = "UNKNOWN";
        Type["PRIMARY_CARE"] = "PRIMARY_CARE";
        Type["TELEMEDICINE"] = "TELEMEDICINE";
        Type["HOME"] = "HOME";
        Type["RETAIL_CLINIC"] = "RETAIL_CLINIC";
        Type["URGENT_CARE"] = "URGENT_CARE";
        Type["SPECIALIST"] = "SPECIALIST";
        Type["AMBULANCE"] = "AMBULANCE";
        Type["DENTIST"] = "DENTIST";
        Type["NURSE_REACHOUT"] = "NURSE_REACHOUT";
        Type["ER"] = "ER";
        Type["POISON_CONTROL"] = "POISON_CONTROL";
        Type["LABOR_AND_DELIVERY"] = "LABOR_AND_DELIVERY";
        Type["VACCINE"] = "VACCINE";
        Type["TESTING"] = "TESTING";
        Type["INFORMATION"] = "INFORMATION";
        Type["HOSPITAL"] = "HOSPITAL";
        Type["POISON_CENTER"] = "POISON_CENTER";
        Type["BEHAVIORAL_HEALTH"] = "BEHAVIORAL_HEALTH";
        Type["LAB"] = "LAB";
        Type["CARDIAC_EVALUATION_CENTER"] = "CARDIAC_EVALUATION_CENTER";
        Type["ASYNCH_CARE"] = "ASYNCH_CARE";
        Type["ASYNCH_CARE_ECZEMA"] = "ASYNCH_CARE_ECZEMA";
        Type["ASYNCH_CARE_COLD_SORES"] = "ASYNCH_CARE_COLD_SORES";
        Type["ASYNCH_CARE_ASTHMA_MEDICATION_REFILL"] = "ASYNCH_CARE_ASTHMA_MEDICATION_REFILL";
        Type["CALL_DOCTOR_OR_ER_NOW"] = "CALL_DOCTOR_OR_ER_NOW";
        Type["OTHER"] = "OTHER";
    })(Type = SourceOfCare.Type || (SourceOfCare.Type = {}));
})(SourceOfCare || (SourceOfCare = {}));
