import '../../../../util/EventLog';
import '../../../clearstep/Types';
import '../../../clearstep/proto/care_locations_pb';
import '../../../clearstep/proto/results_pb';
import { frameAncestorsToPostMessageOrigins } from '../../../clearstep/util/AppHelpers';
import './EventLogPartnerCustomizations';
export var logEventToExternalWindow = function (options) {
    var customData = options.customData;
    var logData = customData && customData['external-window'];
    if (logData) {
        logData.clearstepId = window.conversationId.toString();
        var parentWindow_1 = window.parent;
        var targetOrigins = frameAncestorsToPostMessageOrigins();
        if (Array.isArray(targetOrigins)) {
            targetOrigins.forEach(function (o) {
                parentWindow_1.postMessage(logData, o);
            });
        }
    }
};
export var hcaCustomDataWrapper = 'external-window';
