var _a;
import React from 'react';
import { Severity } from '../../content_editor/Library/Library';
import { ExclamationCircle, TriageRecTriangleExclamation, TriageRecCircleCheck } from '../../SVG';
var levels = {
    Red: {
        backgroundColor: 'bg-red-50',
        textColor: 'text-red-800',
        Icon: function () { return (React.createElement(React.Fragment, null,
            React.createElement(TriageRecTriangleExclamation, { className: "hidden fill-red-700 lg:block", width: "26", height: "24" }),
            React.createElement(TriageRecTriangleExclamation, { className: "block fill-red-700 lg:hidden", width: "20", height: "18" }))); },
    },
    Orange: {
        backgroundColor: 'bg-orange-50',
        textColor: 'text-orange-800',
        Icon: function () { return (React.createElement(React.Fragment, null,
            React.createElement(TriageRecTriangleExclamation, { className: "hidden fill-orange-500 lg:block", width: "26", height: "24" }),
            React.createElement(TriageRecTriangleExclamation, { className: "block fill-orange-500 lg:hidden", width: "20", height: "18" }))); },
    },
    Yellow: {
        backgroundColor: 'bg-yellow-50',
        textColor: 'text-yellow-800',
        Icon: function () { return (React.createElement(React.Fragment, null,
            React.createElement(ExclamationCircle, { pathClassName: "fill-yellow-500", className: "hidden lg:block", width: "24", height: "24" }),
            React.createElement(ExclamationCircle, { pathClassName: "fill-yellow-500", className: "block lg:hidden", width: "20", height: "20" }))); },
    },
    Green: {
        backgroundColor: 'bg-green-50',
        textColor: 'text-green-700',
        Icon: function () { return (React.createElement(React.Fragment, null,
            React.createElement(TriageRecCircleCheck, { pathClassName: "fill-green-700", className: "hidden lg:block", width: "24", height: "24" }),
            React.createElement(TriageRecCircleCheck, { pathClassName: "fill-green-700", className: "block lg:hidden", width: "20", height: "20" }))); },
    },
};
var severityToColor = (_a = {},
    _a[Severity.None] = null,
    _a[Severity.Call911] = levels.Red,
    _a[Severity.ER] = levels.Red,
    _a[Severity.LaborDelivery] = levels.Red,
    _a[Severity.DoctorOrERNow] = levels.Red,
    _a[Severity.UrgentCare] = levels.Orange,
    _a[Severity.DoctorNow] = levels.Yellow,
    _a[Severity.BehavioralHealthNow] = levels.Yellow,
    _a[Severity.DentistNow] = levels.Yellow,
    _a[Severity.TelehealthNow] = levels.Yellow,
    _a[Severity.PoisonCenter] = levels.Orange,
    _a[Severity.DoctorWithin24Hours] = levels.Yellow,
    _a[Severity.BehavioralHealth24Hours] = levels.Yellow,
    _a[Severity.DentistWithin24Hours] = levels.Yellow,
    _a[Severity.TelehealthWithin24Hours] = levels.Yellow,
    _a[Severity.SpecialistOfficeHours] = levels.Yellow,
    _a[Severity.DoctorOfficeHours] = levels.Yellow,
    _a[Severity.BehavioralHealthOfficeHours] = levels.Yellow,
    _a[Severity.DentistOfficeHours] = levels.Yellow,
    _a[Severity.TelehealthOfficeHours] = levels.Yellow,
    _a[Severity.HomeQuarantine] = null,
    _a[Severity.SelfCare] = levels.Green,
    _a[Severity.Vaccine] = null,
    _a[Severity.Testing] = null,
    _a[Severity.Information] = null,
    _a);
export var getNewTriageDesignColor = function (severity) {
    return severityToColor[severity];
};
